<template>
  <b-row>
    <b-col sm="12" xs="12" md="6" lg="6">
      <b-card>
        <b-img
          :src="require('@/assets/images/illustration/badge.svg')"
          class="congratulation-medal"
          alt="Medal Pic"
        />
        <b-col cols="10" class="mb-3">
          <h3 v-if="parrainageValide > 0" class="mb-2 mt-2 pt-50 text-center">
            🥳 Vous avez débloqué -{{ parrainageValide * 20 }} % sur votre prochaine facture !
          </h3>
          <h3 v-else class="mb-2 mt-2 pt-50 text-center">
            😥 Vous n'avez pas encore débloqué de réduction...
          </h3>
        </b-col>
        <h5 class="mb-2">{{ title }}</h5>
        <b-table
          responsive
          striped
          hover
          small
          :fields="fields"
          :items="parrainages"
        >
          <template #cell(etat)="data">
            <b-badge
              v-if="data.item.etat === 1"
              pill
              :variant="`light-success`"
              class="text-capitalize"
            >
              <span>Inscrit</span>
            </b-badge>
            <b-badge
              v-else-if="data.item.etat === 0"
              pill
              :variant="`light-warning`"
              class="text-capitalize"
            >
              <span>Pas encore inscrit</span>
            </b-badge>
          </template>

          <template #cell(date)="data">
            <span>{{ (new Date(data.item.date * 1000)).getDate() }} / {{ (new Date(data.item.date * 1000)).getMonth() }} / {{ (new Date(data.item.date * 1000)).getFullYear() }}</span>
          </template>
        </b-table>
      </b-card>
    </b-col>
    <b-col sm="12" xs="12" md="6" lg="6">
      <b-card>
        <p>Chaque mois, vous avez droit à 5 parrainages. Vous pouvez dès à présent parrainer vos proches et bénéficier de réductions cumulables !</p>
        <h5>Gains PARRAIN</h5>
        <p>
          <ul>
            <li>1 parrainage : 20% de réduction sur votre prochaine facture</li>
            <li>2 parrainages : 40%</li>
            <li>3 parrainages : 60%</li>
            <li>4 parrainages : 80%</li>
            <li>5 parrainages : votre prochain mois OFFERT</li>
          </ul>
        </p>
        <h5>Gains FILLEUL</h5>
        <p>
          <ul>
            <li>100€ de réduction sur son premier mois !</li>
          </ul>
        </p>


        <b-progress height="2rem" max="100" class="mb-2">

          <b-progress-bar animated :value="value" variant="success">
            <strong v-if="parrainageValide == 0">0 / 5 parrainage</strong>
            <strong v-else-if="parrainageValide == 1">{{ parrainageValide }} / 5 parrainage</strong>
            <strong v-else>{{ parrainageValide }} / 5 parrainages</strong>
          </b-progress-bar>
        </b-progress>
        <strong v-if="parrainageValide == 0">Aucun parrainage validé - Parrainez 1 personne pour obtenir 20% de réduction sur votre prochaine facture !</strong>
        <strong v-else-if="parrainageValide == 1">{{ parrainageValide }} parrainage validé - plus que {{ 5 - parrainageValide }} avant de débloquer votre prochain mois gratuit</strong>
        <strong v-else>{{ parrainageValide }} parrainages validés - plus que {{ 5 - parrainageValide }} avant de débloquer votre prochain mois gratuit</strong>



      </b-card>
    </b-col>
    <b-col sm="12" xs="12" md="12" lg="12">
      <section id="apercu">
        <b-card
          title="Parrainer quelqu'un"
        >
          <b-card-body>
            <b-row>
              <b-col cols="12">
                <b-form @submit.prevent>
                  <b-row>
                    <b-col cols="12">
                      <b-input-group
                        size="sm"
                        prepend="@"
                      >
                        <b-form-input
                          debounce="1000"
                          placeholder="E-mail de la personne à parrainer"
                          id="email"
                          v-model="email"
                          class="demo-inline-spacing"
                        />
                      </b-input-group>
                    </b-col>


                    <b-col cols="12" class="mt-2 mb-2">
                      <quill-editor
                        style="border :1px solid grey"
                        v-model="content"
                        :options="editorOption"
                      >
                      <div
                        id="toolbar"
                        slot="toolbar"
                      >
                        <!-- Add a bold button -->
                        <button class="ql-bold">
                          Bold
                        </button>
                        <button class="ql-italic">
                          Italic
                        </button>

                        <!-- Add font size dropdown -->
                        <select class="ql-size">
                          <option value="small" />
                          <!-- Note a missing, thus falsy value, is used to reset to default -->
                          <option selected />
                          <option value="large" />
                          <option value="huge" />
                        </select>

                        <select class="ql-font">
                          <option selected="selected" />
                          <option value="serif" />
                          <option value="monospace" />
                        </select>

                        <!-- Add subscript and superscript buttons -->
                        <button
                          class="ql-script"
                          value="sub"
                        />
                        <button
                          class="ql-script"
                          value="super"
                        />

                      </div>
                    </quill-editor>

                    </b-col>

                    <b-col cols="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mr-1"
                        @click="lancer"
                      >
                        Envoyer ce mail de parrainage
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </section>
    </b-col>
  </b-row>
</template>

<script>
import router from '@/router'
import axios from '@axios'
import {
  BBadge, BTable, BFormSpinbutton, BFormSelect, BInputGroup, BButton, BForm, BFormGroup, BFormInput, BCard, BLink, BCardBody, BRow, BCol, VBToggle, BCarousel, BCarouselSlide, BFormTextarea, BProgress, BImg, BProgressBar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BBadge,
    BTable,
    BFormSpinbutton,
    BFormSelect,
    BInputGroup,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    BLink,
    BRow,
    BCol,
    BCardBody,
    BProgress,
    BProgressBar,
    BImg,
    /* eslint-disable vue/no-unused-components */
    VBToggle,
    BCarousel,
    BCarouselSlide,
    BFormTextarea,
    quillEditor,
    ToastificationContent,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      title: "Mes parrainages en "+(new Date()).toLocaleDateString("fr-FR", { month: 'long' }),
      parrainageValide: 0,
      value: 0,
      editorOption: {
        modules: {
          toolbar: '#toolbar'
        },
        theme: 'bubble',
      },
      content: `<p>Bonjour,</p><p><br></p><p>Je fais appel aux services d'Oscar Black afin de promouvoir mon activité sur Facebook, Instagram et Google via de la publicité ciblée. Cela booste mes ventes, augmente significativement ma visibilité sur mes réseaux sociaux et joue positivement sur mon image de marque.</p><p><br></p><p>Si vous voulez aussi faire de la publicité en ligne, voici un lien pour profiter d’une réduction de 100 euros le premier mois : <a href="https://app.oscar-black.com/register" target="blank">https://app.oscar-black.com/register</a></p><p><br></p><p>Inscrivez-vous avec cette adresse mail : afin de bénéficier de la réduction.</p><h2><br></h2><p>A très vite !</p><p>`+JSON.parse(localStorage.getItem('userData')).fullName+`</p>`,
      parrainages: [],
      email: '',
      media: [],
      ready: false,
      fields: [
        { key: 'email', label: 'E-mail' },
        { key: 'date', label: 'Date d\'envoi' },
        { key: 'etat', label: 'Etat' }
      ],
      edit: null,
      objetsOption: ['Je veux lancer une nouvelle campagne', 'Je veux faire une modification sur une campagne existante', 'Je veux changer mon budget quotidien', 'J\'ai un problème avec mon compte publicitaire'],
      mediasOption: [],
      message: '',
    }
  },
  created() {
    axios
      .get('https://app2.oscar-black.com/api/parrainages')
      .then(response => {
        this.parrainages = response.data
        this.parrainageValide = 0;
        for(var i = 0 ; i < this.parrainages.length ; i++) {
          if(this.parrainages[i].etat === 1) {
            this.parrainageValide++;
          }
        }
        this.value = this.parrainageValide / 5 * 100;
        setTimeout(() => { this.ready = true }, 500)
      })
      .catch(() => {})
  },
  methods: {
    lancer() {
      const data = {
        email: this.email,
        message: this.content,
      }
      axios
        .post('https://app2.oscar-black.com/api/parrainage', data)
        .then(response => {
          this.parrainages = response.data.resultat;
          this.parrainageValide = 0;
          for(var i = 0 ; i < this.parrainages.length ; i++) {
            if(this.parrainages[i].etat === 1) {
              this.parrainageValide++;
            }
          }
          this.value = this.parrainageValide / 5 * 100;
          if(response.data.error != '') {
            if(response.data.error == "Trop") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Vous avez atteint votre quota de 5 parrainages.',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            }
            else if(response.data.error == "Déjà parrainé") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.email + ' a déjà été parrainé ce mois-ci...',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            }
            else if(response.data.error == "Adresse invalide") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'L\'adresse mail renseignée semble être invalide.',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            }
            else if(response.data.error == "Déjà client") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'L\'adresse mail renseignée est déjà cliente Oscar Black.',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            }
          }
          this.email = ''
          this.content = `<p>Bonjour,</p><p><br></p><p>Je fais appel aux services d'Oscar Black afin de promouvoir mon activité sur Facebook, Instagram et Google via de la publicité ciblée. Cela booste mes ventes, augmente significativement ma visibilité sur mes réseaux sociaux et joue positivement sur mon image de marque.</p><p><br></p><p>Si vous voulez aussi faire de la publicité en ligne, voici un lien pour profiter d’une réduction de 100 euros le premier mois : <a href="https://app.oscar-black.com/register" target="blank">https://app.oscar-black.com/register</a></p><p><br></p><p>Inscrivez-vous avec cette adresse mail : afin de bénéficier de la réduction.</p><h2><br></h2><p>A très vite !</p><p>`+JSON.parse(localStorage.getItem('userData')).fullName+`</p>`
          setTimeout(() => { this.ready = true }, 500)
        })
        .catch(() => {})
    },
  },
  watch: {
    email(val) {
      this.content = `<p>Bonjour,</p><p><br></p><p>Je fais appel aux services d'Oscar Black afin de promouvoir mon activité sur Facebook, Instagram et Google via de la publicité ciblée. Cela booste mes ventes, augmente significativement ma visibilité sur mes réseaux sociaux et joue positivement sur mon image de marque.</p><p><br></p><p>Si vous voulez aussi faire de la publicité en ligne, voici un lien pour profiter d’une réduction de 100 euros le premier mois : <a href="https://app.oscar-black.com/register" target="blank">https://app.oscar-black.com/register</a></p><p><br></p><p>Inscrivez-vous avec cette adresse mail : <strong>`+val+`</strong> afin de bénéficier de la réduction.</p><h2><br></h2><p>A très vite !</p><p>`+JSON.parse(localStorage.getItem('userData')).fullName+`</p>`
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  #sidebar-right {
    width: 550px !important;
  }
</style>
